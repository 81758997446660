<template>
	<div class="RPA">
		<div class="left">
			<router-link to="/manage/rpa/manage">
				<img src="@/assets/img/rpa/icon_manage.png" alt="" />
				<span>RPA流程管理</span>
			</router-link>
			<router-link to="/manage/rpa/plan">
				<img src="@/assets/img/rpa/icon_plan.png" alt="" />
				<span>计划管理</span>
			</router-link>
			<router-link to="/manage/rpa/log">
				<img src="@/assets/img/rpa/icon_log.png" alt="" />
				<span>任务日志</span>
			</router-link>
		</div>
		<router-view class="right" />
	</div>
</template>

<script>
export default {
	components: {},
	name: "RpaPage",
	data() {
		return {};
	},
};
</script>
<style lang="less" scoped>
.RPA {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	padding-left: 15px;
	.left {
		width: 210px;
		background-color: #fff;
		padding-top: 38px;
		a {
			display: flex;
			align-items: center;
			padding-left: 24px;
			margin: 4px 0;
			border-right: 3px solid transparent;
			img {
				display: block;
				margin-right: 6px;
			}
			span {
				font-size: 14px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #374567;
				line-height: 46px;
			}
			&.router-link-exact-active {
				background-color: #f0f7ff;
				border-color: #4c84ffed;
			}
		}
	}
	.right {
		flex: 1;
		min-height: calc(100vh - 100px);
		background-color: #fff;
		margin-left: 11px;
	}
}
</style>
